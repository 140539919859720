import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import MainLayout from '../layouts/full-layout/MainLayout';

/* ****Pages***** */
const Home = Loadable(lazy(() => import('../pages/home')));
// const SocialWorkerEdit = Loadable(lazy(() => import('../pages/socialWorkers/SocialWorkerEdit')));
// const SocialWorkerAdd = Loadable(lazy(() => import('../pages/socialWorkers/SocialWorkerAdd')));
// const SocialWorkerMigrate = Loadable(
//   lazy(() => import('../pages/socialWorkers/SocialWorkerMigrate')),
// );
// const SocialWorkerProfile = Loadable(lazy(() => import('../pages/my-page/MyPage')));
// const ProviderList = Loadable(lazy(() => import('../pages/providers/ProviderList')));
// const ProviderEdit = Loadable(lazy(() => import('../pages/providers/ProviderEdit')));

/* ****Routes***** */
const Router = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      // { path: '/page', element: <Navigate to="/" /> },
      { path: '/', exact: true, element: <Home /> },
      // { path: SW_LIST, exact: true, element: <SocialWorkerList /> },
      // { path: SW_EDIT, exact: true, element: <SocialWorkerEdit /> },
      // { path: SW_ADD, exact: true, element: <SocialWorkerAdd /> },
      // { path: SW_MIGRATE, exact: true, element: <SocialWorkerMigrate /> },
      // { path: PROVIDER_LIST, exact: true, element: <ProviderList /> },
      // { path: PROVIDER_EDIT, exact: true, element: <ProviderEdit /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    // children: [{ path: '404', element: <Error /> }],
  },
];

export default Router;
