import React from 'react';
import { experimentalStyled, Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './header/Header';

const MainWrapper = experimentalStyled('div')(() => ({
  overflow: 'hidden',
  width: '100%',
}));
const PageWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',

  backgroundColor: theme.palette.background.default,
}));

const MainLayout = () => (
  <MainWrapper>
    <Header />
    <PageWrapper>
      <Container
        maxWidth={false}
        sx={{ paddingLeft: '0px !important', paddingRight: '0px !important' }}
      >
        <Box sx={{ minHeight: 'calc(100vh - 170px)' }}>
          <Outlet sx={{ height: '100px' }} />
        </Box>
      </Container>
    </PageWrapper>
  </MainWrapper>
);

export default MainLayout;
