import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useMediaQuery } from '@mui/material';

const pages = [
  { title: 'خانه' },
  { title: 'درباره‌ما' },
  { title: 'خدمات' },
  { title: 'تماس با ما' },
  { title: 'وبلاگ' },
];
const scrollConstant = 12;
function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [scroll, setScroll] = useState(0);
  const [clicked, setClicked] = useState(false);

  const handleScroll = () => {
    setScroll(document.documentElement.scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <AppBar
      sx={{
        backgroundColor: scroll <= scrollConstant ? 'transparent' : 'white',
        height: scroll <= scrollConstant ? '110px' : '60px',
        transition: '.6s all ease',
      }}
      elevation={scroll < scrollConstant ? 0 : 2}
    >
      <Container sx={{ minWidth: '100%' }}>
        <Toolbar disableGutters sx={{ direction: !lgUp && 'ltr' }}>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              color: scroll <= scrollConstant ? 'white' : 'black',
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {scroll < scrollConstant ? (
            <Typography
              variant="h5"
              sx={{ mt: 2, color: 'white', marginLeft: '40px', fontSize: 24, fontWeight: 800 }}
            >
              دارکوب
            </Typography>
          ) : (
            <img src="/logo.webp" alt="logo" style={{ marginLeft: '35px', maxWidth: '100px' }} />
          )}

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, index) => (
              <Button
                disableRipple
                key={page.title}
                onClick={() => setClicked(index)}
                sx={{
                  fontSize: 18,
                  fontWeight: 300,
                  mt: 2,
                  color: scroll < scrollConstant ? 'white' : 'black',
                  display: 'inline-block',
                  borderBottom: clicked === index && '0px solid white',
                  marginRight: '39px',
                  WebkitTransition: '0.6s ease',
                  transition: '.6s ease',
                  '&:hover': {
                    WebkitTransition: '0.5s ease',
                    transition: '.5s ease',
                    borderBottom: scroll < scrollConstant ? '1px solid white' : '1px solid black',
                    marginRight: '35px',
                  },
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
          {lgUp && (
            <>
              <Typography variant="subtitle1" sx={{ marginLeft: '30px', fontSize: 18 }}>
                قیمت
              </Typography>
              <Button variant="contained" color="primary">
                تماس با ما
              </Button>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
