import React from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import ThemeSettings from './layouts/full-layout/themeBuilder/ThemeSettings';
import Router from './routes/Router';
import './resources/styles/css/style.css';

const App = () => {
  const routing = useRoutes(Router);
  const theTheme = ThemeSettings();
  return (
    <div>
      <ThemeProvider theme={theTheme}>
        <CssBaseline />
        {routing}
      </ThemeProvider>
    </div>
  );
};
export default App;
