import { applyMiddleware, compose } from '@reduxjs/toolkit';
import { legacy_createStore as createStore } from 'redux';

import thunk from 'redux-thunk';
import reducers from './reducers';

// Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

export default store;
