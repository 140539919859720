import { CircularProgress } from '@mui/material';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import App from './App';
import store from './redux/store';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={store}>
    <Suspense fallback={<CircularProgress />}>
      <HashRouter>
        <App />
      </HashRouter>
    </Suspense>
    ,
  </Provider>,
);
